.container {
  /* background-color: red; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  gap: 2em;
  /* padding: 1em; */
}

.portfolio {
  margin: 0 auto;
  max-width: 1280px;
}