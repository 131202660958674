.container {
  display: grid;

}

.container img {
  width: 100%;
  grid-area: 1/1;
  opacity: 0;
  transition: 0.3s;
}
.skeleton {
  grid-area: 1/1;
  height: 280px;
  background-color: #ddd;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 200%;
  animation: skeleton 1.5s infinite linear;
}

@keyframes skeleton {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}