@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');
/* ________________________  */
/* -------- colors --------  */

:root {
  --purple: #9792e3;
  --white: #eeffec;
  --green: #61e786;
  --dark1: #181818;
  --dark2: #222222;
}

a {
  color: var(--white);
  text-decoration: none;
  font-family: 'Roboto Mono', monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: var(--dark1);
  color: var(--white);
  margin: 0 ;
  padding: 0;
}


img {
  max-width: 100%;
  display: block;
  font-size: 0;
  outline: none;
}