.wrapper {
  border-radius: 0.6em;
  display: flex;
  background-color: var(--dark2);
  /* padding: 2em; */
  overflow: hidden;
}

.image {
  width: 70%;
  margin: 0;
}

.details {
  width: 30%;
  margin: 0;
  padding: 1em;
}

.details span {
  background-color: white;
  padding: 0.2em;
  margin-right: 0.5em;
  color: black;
  border-radius: 0.6em;
}