.profile {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.profile img {
  border-radius: 50%;
  max-width: 100%;
  width: 260px;
}

.profile h1 {
  font-family: 'Roboto Mono', monospace;
}

.profile .squares span {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.profile .squares span:nth-child(1) {
  background-color: var(--green);
  margin-right: 4px;
}

.profile .squares span:nth-child(2) {
  background-color: var(--purple);
  margin-right: 4px;
}

.profile .squares span:nth-child(3) {
  background-color: var(--white);
}

section {
  max-width: 600px;
  margin: 32px auto;
}

.links {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16px auto;
  gap: 16px;
}

.links a {
  flex-basis: 200px;
  flex-grow: 1;
}

.links__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--dark2);
  height: 72px;
}

.portfolio {
  background: linear-gradient(180deg, var(--green) 0%, #066c23 100%);
}

svg {
  font-size: 36px;
  margin-right: 8px;
  color: var(--white);
}

.rgb {
  height: 280px;
  width: 280px;
  position: relative;
  margin: 0 auto;
  display: grid;
  place-items: center;
}

.rgb::before {
  content: '';
  height: 280px;
  width: 280px;
  border-radius: 50%;
  z-index: -1;
  position: absolute;
  background: linear-gradient(var(--green), var(--purple), red);
  animation: animate 3s linear infinite;
}

@keyframes animate {
  0% {
    filter: hue-rotate(0deg) brightness(100%) blur(10px);
  }
  100% {
    filter: hue-rotate(360deg) brightness(100%) blur(10px);
  }
}
